<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-edit mr-3"></i>Duplicate Courses</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                            <form @submit.prevent="duplicateCourse" class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div class="row">

                                        <div class="col-lg-12 form-group mb-3">
                                            <label for="">New Course Name</label>
                                            <input v-model="course.name" type="text" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Name">
                                        </div>
                                        <div class="col-lg-12 form-group text-right my-4" v-if="!loadCourse">
                                            <router-link to="/admin/courses" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                            <input type="Submit" class="btn btn-current" value="Duplicate Now">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center d-flex">
            <div class="col-xl-12 col-lg-12 text-center py-3">
                <p>{{ duplicateMessage }}</p>
            </div>
            <div class="col-xl-12 col-lg-12 text-center py-3">
                <p>{{ errorMessage }}</p>
            </div>
            <div class="col-xl-12 col-lg-12 text-center py-3">
                <div class="my-3" v-show="loadCourse">

                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<style  scoped>
.cropper {
  height: 600px;
  background: #DDD;
}
</style>
<script>
    import axios from 'axios'
    import 'vue-advanced-cropper/dist/style.css';
    export default {
        name: "DuplicateCourses",
        data(){
            return {
                duplicateMessage: '',
                errorMessage: '',
                loadCourse: true,
                paramsId: this.$route.params.idCourse,
                course: {
                    id: '',
                    name: '',
                },
            }
        },
        mounted() {
            this.getCourse()
        },
        methods: {
            async getCourse(){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/course?slug=` + this.paramsId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then( async (res) => {
                    this.loadCourse = false
                    this.course.id = res.data.id
                    this.course.name = `[COPY OF] ${res.data.name}`
                }).catch((err) => console.error(err))
            },
            alert(message) {
                alert(message)
            },
            async duplicateCourse(){
                // DUPLICATE COURSE
                this.loadCourse = true
                this.duplicateMessage = 'Creating new course, this will take a while, please wait and do not close or refresh this page...'
                this.errorMessage = ''
                const payloadDuplicateCourse = {
                    id: this.course.id,
                    name: this.course.name
                }
                console.log(payloadDuplicateCourse)
                await axios.post(`${process.env.VUE_APP_URL_API}/admin/course/duplicate`, payloadDuplicateCourse, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                    timeout: 600000
                }).then(async res => {
                    const payloadDuplicateMaterial = {
                        from_course_id: this.course.id,
                        to_course_id: res.data.id
                    }
                    this.duplicateMessage = 'Duplicating materials and assignments, this can take a few minutes due to the a lot of data is being copied, please wait and do not close or refresh this page...'
                    await axios.post(`${process.env.VUE_APP_URL_API}/admin/course/duplicate/material`, payloadDuplicateMaterial, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.token}`
                        },
                        timeout: 600000
                    }).then(res => {
                        this.$swal({
                            toast: true,
                            title: 'Success Duplicate Course !',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        this.$router.push({name: 'CoursesAdmin'})
                    }).catch(err => {
                        this.errorMessage = 'Error duplicating course, please try again using another name'
                        console.log(err.response)
                    })
                }).catch(err => {
                    if (err.response.status === 504) {
                        this.$router.push({name: 'CoursesAdmin'})
                    }else {
                        this.errorMessage = 'Error duplicating course, please try again using another name'
                        console.log(err.response)
                    }
                })
                this.duplicateMessage = ''
                this.loadCourse = false
            },
            async updateCourse(){
                var data = {
                    id: this.course.id,
                    banner: this.course.banner,
                    name: this.course.name,
                    category: this.course.category,
                    level: this.course.level,
                    description: this.course.description,
                    price: this.course.price,
                    price_cycle_1: this.course.price_cycle_1,
                    price_cycle_2: this.course.price_cycle_2,
                    price_cycle_3: this.course.price_cycle_3,
                    has_special: this.course.has_special,
                    price_special: this.course.price_special,
                    price_special_cycle_1: this.course.price_special_cycle_1,
                    price_special_cycle_2: this.course.price_special_cycle_2,
                    price_special_cycle_3: this.course.price_special_cycle_3,
                    objective: this.course.objective,
                    length: this.course.length,
                    // start_date: this.course.start_date,
                    start_date_1: this.course.start_date_1,
                    end_date_1: this.course.end_date_1,
                    start_date_2: this.course.start_date_2,
                    end_date_2: this.course.end_date_2,
                    start_date_3: this.course.start_date_3,
                    end_date_3: this.course.end_date_3,
                    start_time: this.course.start_time,
                    end_time: this.course.end_time,
                    point_id: this.course.point_id,
                    point: this.course.point,
                    type: this.course.type,
                    used_limit: this.course.used_limit,
                    point_status: this.course.point_status,
                    cycles: this.course.cycles,
                    _method: 'patch'
                }
                await axios.post(`${process.env.VUE_APP_URL_API}/admin/course`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    this.$swal({
                        toast: true,
                        title: 'Success Edit This Course !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    // this.$toastr.success(res.data.message, 'Success Edit This Course !');
                    this.$router.push({name: 'GetCoursesAdmin'})
                }).catch(err => {
                    if (err.response.status == 422){
                        this.validationErrors = Object.values(err.response.data.errors);
                        // this.$toastr.error(this.validationErrors[0][0], 'Failed Edit This Course !');
                        this.$swal({
                            toast: true,
                            title: 'Failed Edit This Course !',
                            text: this.validationErrors[0][0],
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                    console.log(err.response)
                })
            }
        },
        computed: {
            fullpath: function() {
                return process.env.VUE_APP_URL_CLOUD + this.course.banner;
            }
        }
    }
</script>
